<script>
export default {
  components: {
    Modal: () => import("@/components/modals/Modal.vue"),
    ModelCard: () => import("@/views/main/operationNow/CardModelDuplicate.vue"),
  },
  data() {
    return {
      newModels: [],
    };
  },
  props: {
    closeModal: {
      Type: Function,
      default: () => ({}),
    },
  },
  computed: {
    duplicateModels() {
      const models = this.$store.getters["connectedUsers/getModelsInRoom"] || [];
      const search = models.reduce((acc, model) => {
        acc[model?.roomTemp?.roomName || model.room] = ++acc[model?.roomTemp?.roomName || model.room] || 0;
        return acc;
      }, {});

      const duplicates = models.filter((model) => {
        return search[model?.roomTemp?.roomName || model.room];
      });
      return duplicates.sort((a, b) => (a?.roomTemp?.roomName || a.room).localeCompare(b?.roomTemp?.roomName || b.room));
    },
  },
  methods: {
    close() {
      this.closeModal();
    },
  },
};
</script>

<template>
  <Modal class="modalMonitor" :showMask="true" @onCancel="closeModal">
    <template v-slot:header>
      <div class="modalMonitor__header">
        <h2 class="modalMonitor__title">Duplicadas</h2>
        <button class="modalMonitor__close" @click="closeModal">
          <iconic class="modalMonitor__icon" name="close" />
        </button>
      </div>
    </template>
    <template v-slot:body>
      <div class="modalMonitor__cardContent">
        <ModelCard class="modalMonitor__modelCard" :user="model" v-for="(model, idx) in duplicateModels" :key="model._id + idx" />
      </div>
    </template>
    <template v-slot:footer>
      <div></div>
    </template>
  </Modal>
</template>

<style lang="scss">
.modalMonitor {
  .modal-g__container {
    width: 100%;
    max-width: 320px;
  }
  &__header {
    @include Flex(row, space-between);
  }
  &__close {
    @include Flex(row);
    height: 35px;
    width: 35px;
    color: $primary-color;
    border: none;
    border-radius: 50%;
    background: none;
    cursor: pointer;
    &:hover {
      background: #bd09092e;
      transition: 0.3s ease-in-out;
    }
  }
  &__searchContainer,
  &__header,
  &__cardContent {
    width: 100%;
  }
  &__searchContainer {
    @include Flex(row, flex-start);
    position: relative;
  }
  &__search {
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 25px;
    background-color: #5b59572b;
    color: black;
    font-size: 20px;
    transition: 0.7s ease-in-out;
    z-index: 15;
    &:hover,
    &:focus,
    &--isSearch {
      width: 100%;
      padding: $mpadding $mpadding * 2;
    }
  }
  &__searchIcon {
    position: absolute;
    top: 12px;
    left: 12px;
    font-size: 17px;
    z-index: 10;
  }
  &__cardContent {
    @include Flex(column, flex-start);
    text-align: center;
    max-height: calc(100vh - 100px);
    margin-top: $mpadding / 2;
    overflow-y: auto;
    &::-webkit-scrollbar-track {
      background-color: $alto;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $chicago;
    }
    &::-webkit-scrollbar {
      width: 8px;
    }
  }
  &__modelCard {
    margin: $mpadding/4 0;
  }
}
</style>
